import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../componants/header';
import Footer from '../../componants/footer';
import Category from '../Category/Category';
import Productimage from '../Productimage/Productimage';
import { Link, useLocation } from 'react-router-dom';

function Products() {

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const receivedValue = params.get('value');
  const receivedname = params.get('name');

  const [crudOperation, setCrudOperation] = useState('read'); // Default to 'read'
  const [items, setItems] = useState([]);
  
  
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  

  const [subcatoptions, setsubcatOptions] = useState([]);
  const [selectedsubcatOption, setsubcatSelectedOption] = useState('');


  const [stateoptions, setstateOptions] = useState([]);
  const [selectedstateOption, setstateSelectedOption] = useState('');

  const [cityoptions, setcityOptions] = useState([]);
  const [selectedcityOption, setcitySelectedOption] = useState('');

  const [formData, setFormData] = useState({
    id: '',
    name: '' ,
    state:'',
    city:'',
    category: '',
    subcategory:'',
    description:'',
    usedfor:'',
    adtype:'',
    leadtimeindays:'',
    span:'',
    rate:'',keyword:'',
    status:''
  });

  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };
  useEffect(() => {
    if(receivedValue==='')
    {
fetchItems();
    }else
    {
 productsubcat();
    }
   
    
    fetchstateItems();
    fetch();
  }, []);


  const fetchItems = async () => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/Product/productall?tokens='+tokens+'');
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const productsubcat = async () => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/Product/productsubcat?subcat='+ receivedValue+'&tokens='+tokens+'');
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };
  const fetchstateItems = async () => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/state/state?tokens='+tokens+'');
      setstateOptions(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const fetchcityItems = async (id) => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/city/Stateid?id='+id+'&tokens='+tokens+'');
      setcityOptions(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };


  const fetchsubcatItems = async (id) => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/SubCategory/subcategoryid?id='+id+'&tokens='+tokens+'');
      setsubcatOptions(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };
  
 const fetch=async()=>{
  
    const response1 = await axios.get('https://api.myonventure.com/api/Categories/categoryall?tokens=1');
        
          // Update state with fetched data
          setOptions(response1.data);
   };
  
   const handleAddItem = async () => {
    try {
    const headers = { 'content-type': 'application/json'}  
    //const headers = { 'Content-Type': 'multipart/form-data' };
      const postData = new FormData();
      
      // Populate FormData object with formData fields
      postData.append('name', formData.name);
      postData.append('state', formData.state);
      postData.append('city', formData.city);
      postData.append('category', formData.category);
      postData.append('subcategory', formData.subcategory);
      postData.append('description', formData.description);
      postData.append('usedfor', formData.usedfor);
      postData.append('adtype', formData.adtype);
      postData.append('leadtimeindays', formData.leadtimeindays);

      postData.append('span', formData.span);
      postData.append('rate', formData.rate);
     
      postData.append('status', '1');
      postData.append('keyword', formData.keyword);
     
      
      const response = await axios.post('https://api.myonventure.com/api/Product/Insert',postData,{'headers':headers});
      
      console.log(response.data); // Log response data
      fetchItems();
      setItems([...items, response.data]);
      
      // Clear formData fields
      setFormData({
        id: '',
        name: '' ,
    state:'',
    city:'',
    category: '',
    subcategory:'',
    description:'',
    usedfor:'',
    adtype:'',
    leadtimeindays:'',
    span:'',
    rate:'',
    status:'',keyword:''
      });
      
      handleSwitchOperation('read');
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await axios.get('https://api.myonventure.com/api/Product/Delete?id='+id+'');
      setItems(items.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleEditItem = async (dataitem1) => {
    try {
      const headers = { 'content-type': 'application/json'}  
      const body=JSON.stringify(formData);
      console.log(formData.Myfile); 
      const postData = new FormData();
      postData.append('id', formData.id);
      postData.append('name', formData.name);
      postData.append('state', formData.state);
      postData.append('city', formData.city);
      postData.append('category', formData.category);
      postData.append('subcategory', formData.subcategory);
      postData.append('description', formData.description);
      postData.append('usedfor', formData.usedfor);
      postData.append('adtype', formData.adtype);
      postData.append('leadtimeindays', formData.leadtimeindays);

      postData.append('span', formData.span);
      postData.append('rate', formData.rate);
      postData.append('status', formData.status);
      postData.append('keyword', formData.keyword);
      
          const response = await axios.post('https://api.myonventure.com/api/Product/Update', postData,{'headers':headers});
         
          setEditingItemId(null);
          fetchItems();
          setItems([...items, response.data]);
          setFormData({ id: '',name: '' ,
          state:'',
          city:'',
          category: '',
          subcategory:'',
          description:'',
          usedfor:'',
          adtype:'',
          leadtimeindays:'',
          span:'',
          rate:'',
          status:'',keyword:'' });
          handleSwitchOperation('read');
   //   setItems(items.map(item => item.id === id ? { ...item, formData } : item));
     
    } catch (error) {
      console.error('Error editing item:', error);
    }
  };

  const showedititem=async(dataitem)=>{
    fetch();
    console.log(dataitem);
    setEditingItemId(dataitem.id);
    setstateSelectedOption(dataitem.state);
    fetchcityItems(dataitem.state);
    setcitySelectedOption(dataitem.city);
    fetchcityItems(dataitem.state);
    setSelectedOption(dataitem.category);
    fetchsubcatItems(dataitem.category);
    setsubcatSelectedOption(dataitem.subcategory);
    console.log(selectedOption);
     
    setFormData({ id: dataitem.id, name: dataitem.name,
    state:dataitem.state,
    city:dataitem.city,
    category: dataitem.category,
    subcategory:dataitem.subcategory,
    description:dataitem.description,
    usedfor:dataitem.usedfor,
    adtype:dataitem.adtype,
    leadtimeindays:dataitem.leadtimeindays,
    span:dataitem.span,
    rate:dataitem.rate,
    keyword:dataitem.keyword
  });
    
    handleSwitchOperation('Edit');
  };

  const handleFileChange = (e) => {
    // Update the Myfile field in the formData state with the selected file object
    setFormData({ ...formData, Myfile: e.target.files[0] });
  };
  const setData=async(id)=>{
    setSelectedOption(id);
    
    setFormData({ ...formData, category: id });
    fetchsubcatItems(id);
  };

  const setsubcatData=async(id)=>{
    setsubcatSelectedOption(id);
    
    setFormData({ ...formData, subcategory: id });
    
  };
  const setstateData=async(id)=>{
    setstateSelectedOption(id);
    
    setFormData({ ...formData, state: id });
    fetchcityItems(id);
  };

  const setcityData=async(id)=>{
    setcitySelectedOption(id);
    
    setFormData({ ...formData, city: id });
  
  };

  return (
    <><Header>
          <div className="container-fluid">
                    {/*         <!-- Page Heading --> */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Product</h1>
                    <button className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onClick={() => handleSwitchOperation('add')}><i
                        className="fas fa-plus fa-sm text-white-50"></i> Add New</button>
                     <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                        className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
                </div>

                    
             
                <div className="row">

                    {/*     <!-- Area Chart --> */}
                    <div className="col-lg-12">
                        <div className="card shadow mb-4">
                            {/*  <!-- Card Header - Dropdown --> */}
                            <div
                                className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">Product View/Add/Update</h6>
                                <div className="dropdown no-arrow">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="dropdownMenuLink">
                                        <div className="dropdown-header">Dropdown Header:</div>
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                            {/*      <!-- Card Body --> */}
                            <div className="card-body">
                            <div>

                          
    <div >
       {/*  Read Operation Start  */}
       {crudOperation === 'read' && (
    <table className='table table-bordered table-responsive'>
    <thead><tr>
                <th>Action</th>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>SubCategory </th>
                <th>Category </th>
                <th>City </th>
                <th>State </th>
                <th>Details </th>
                <th>Used For </th>
                <th>Ad Type </th>
                <th>Lead time in days </th>
                <th>span </th>
                <th>rate </th>   
                <th>Keyword </th>              
            </tr></thead> 
            {items.map((item, index) => {
              var dtl='';
              if (typeof item.description === 'string') {
              dtl=item.description.substring(0,50);
             } 
     return (
<tbody><tr  key={index}>
                <td width={130} className='form-inline'>  
               {/*  <button className='btn btn-sm btn-danger' onClick={() => handleDeleteItem(item.id)}>Delete</button>*/} 
         &nbsp;   <button  className='btn btn-sm  btn-info' onClick={() => showedititem(item)}>Edit</button>    &nbsp;   <button  className='btn btn-sm  btn-info' onClick={() => showedititem(item)}> Photo Upload</button></td>
                <td>{index + 1}</td>
                <td>{item.name}</td> 
                <td>{item.subcategoryname}</td> 
                <td>{item.categoryname}</td>               
                <td>{item.cityname}</td> 
                <td>{item.statename}</td> 
                <td class='tooltip-test' title={item.description}>{dtl}...</td> 
                <td>{item.usedfor}</td> 
                <td>{item.adtype}</td> 
                <td>{item.leadtimeindays}</td> 
                <td>{item.span}</td> 
                <td>{item.rate}</td> 
                <td>{item.keyword}</td> 
            </tr></tbody>
   ) })}
            
           </table>
       )}
           {/*  Read Operation END  */}
      
       {/*  Add Operation Start  */}
       {crudOperation === 'add' && (
           <div className='form-inline'>


<table className='table '>
  <tbody>
  <tr><td> Select State</td><td> <select className='form-control'
           value={selectedstateOption}
           onChange={e =>  setstateData(e.target.value)}>
           <option value="">Select an option</option>
           {stateoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
         <tr><td> Select City</td><td> <select className='form-control'
           value={selectedcityOption}
           onChange={e =>  setcityData( e.target.value )}>
           <option value="">Select an option</option>
           {cityoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
         <tr><td> Select Category</td><td> <select className='form-control'
           value={selectedOption}
           onChange={e =>  setData(e.target.value)}>
           <option value="">Select an option</option>
           {options.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
         <tr><td> Select Sub Category</td><td> <select className='form-control'
           value={selectedsubcatOption}
           onChange={e => setsubcatData( e.target.value )}>
           <option value="">Select an option</option>
           {subcatoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
  <tr><td>    Enter Product Name </td><td> <input className='form-control'
            type="text"  style={{width: '100%'}}
            placeholder="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          /></td></tr>
           <tr><td>   Description</td><td> <textarea className='form-control'
          rows={10} style={{width: '100%'}}
            placeholder="Name"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          /></td></tr>
         <tr><td>    Enter usedfor </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.usedfor}
            onChange={(e) => setFormData({ ...formData, usedfor: e.target.value })}
          /></td></tr>
           <tr><td>    Enter adtype </td><td> <input className='form-control'
          type="text"
          placeholder="Name"
          value={formData.adtype}
          onChange={(e) => setFormData({ ...formData, adtype: e.target.value })}
        /></td></tr> <tr><td>    Enter lead time in days </td><td> <input className='form-control'
        type="text"
        placeholder="Name"
        value={formData.leadtimeindays}
        onChange={(e) => setFormData({ ...formData, leadtimeindays: e.target.value })}
      /></td></tr>
       <tr><td>    Enter span </td><td> <input className='form-control'
      type="text"
      placeholder="Name"
      value={formData.span}
      onChange={(e) => setFormData({ ...formData, span: e.target.value })}
    /></td></tr> 
    <tr><td>    Enter rate </td><td> <input className='form-control'
      type="text"
      placeholder="Name"
      value={formData.rate}
      onChange={(e) => setFormData({ ...formData, rate: e.target.value })}
    /></td></tr> 
      <tr><td>    Enter Keyword </td><td> <input className='form-control'
      type="text"
      placeholder="Name"
      value={formData.keyword}
      onChange={(e) => setFormData({ ...formData, keyword: e.target.value })}
    /></td></tr> 
    
  <tr><td></td><td>   <button className='btn btn-info'  onClick={handleAddItem}>Save</button> 
       &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
      </td></tr></tbody>
</table>

            
     
        
           
   </div>)}  
         {/*  Add Operation END  */}
     
         {/*  Edit Operation START  */}      
    {crudOperation === 'Edit' && ( <div className='form-inline'>

    <table className='table '><tbody>
 
    <tr><td> Select State</td><td> <select className='form-control'
           value={selectedstateOption}
           onChange={e =>  setstateData(e.target.value)}>
           <option value="">Select an option</option>
           {stateoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
         <tr><td> Select City</td><td> <select className='form-control'
           value={selectedcityOption}
           onChange={e =>  setcityData( e.target.value )}>
           <option value="">Select an option</option>
           {cityoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
         <tr><td> Select Category</td><td> <select className='form-control'
           value={selectedOption}
           onChange={e =>  setData(e.target.value)}>
           <option value="">Select an option</option>
           {options.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
         <tr><td> Select Sub Category</td><td> <select className='form-control'
           value={selectedsubcatOption}
           onChange={e => setsubcatData( e.target.value )}>
           <option value="">Select an option</option>
           {subcatoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
  <tr><td>    Enter Product Name </td><td> <input className='form-control'
            type="text"  style={{width: '100%'}}
            placeholder="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          /></td></tr>
           <tr><td>   Description</td><td> <textarea className='form-control'
          rows={10} style={{width: '100%'}}
            placeholder="Name"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          /></td></tr>
         <tr><td>    Enter usedfor </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.usedfor}
            onChange={(e) => setFormData({ ...formData, usedfor: e.target.value })}
          /></td></tr>
           <tr><td>    Enter adtype </td><td> <input className='form-control'
          type="text"
          placeholder="Name"
          value={formData.adtype}
          onChange={(e) => setFormData({ ...formData, adtype: e.target.value })}
        /></td></tr> <tr><td>    Enter lead time in days </td><td> <input className='form-control'
        type="text"
        placeholder="Name"
        value={formData.leadtimeindays}
        onChange={(e) => setFormData({ ...formData, leadtimeindays: e.target.value })}
      /></td></tr>
       <tr><td>    Enter span </td><td> <input className='form-control'
      type="text"
      placeholder="Name"
      value={formData.span}
      onChange={(e) => setFormData({ ...formData, span: e.target.value })}
    /></td></tr> 
    <tr><td>    Enter rate </td><td> <input className='form-control'
      type="text"
      placeholder="Name"
      value={formData.rate}
      onChange={(e) => setFormData({ ...formData, rate: e.target.value })}
    /></td></tr>
    <tr><td>    Enter Keyword </td><td> <input className='form-control'
      type="text"
      placeholder="Name"
      value={formData.keyword}
      onChange={(e) => setFormData({ ...formData, keyword: e.target.value })}
    /></td></tr> 
    
    <tr><td>Product Image Upload</td><td><Productimage id={editingItemId} /></td></tr>
    
     <tr><td></td><td>             <button className='btn btn-info' onClick={handleEditItem}>Update Product</button>
    &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
</td></tr>
    </tbody>
</table>
   
    </div>
      )
    }
      {/*  Edit Operation END  */}  
    
      
    </div>
    </div>
                            </div>
                        </div>
                    </div>

                    </div>   
                </div>
    <Footer/></Header></>
  );
}

export default Products;