import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../componants/header';
import Footer from '../../componants/footer';
import Category from '../Category/Category';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
function Advertisment() {
  const [crudOperation, setCrudOperation] = useState('read'); // Default to 'read'
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
 
  const [stateoptions, setstateOptions] = useState([]);
  const [selectedstateOption, setstateSelectedOption] = useState('');

  const [cityoptions, setcityOptions] = useState([]);
  const [selectedcityOption, setcitySelectedOption] = useState('');

  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setimage] = useState('');
  const [formData, setFormData] = useState({
    id: '',
    pagelocation: '' ,
    companyname:'',amount:'',state:'',
    city:'',country:'1',fromdate: '',
    todate:'',
    image:'',
    Myfile:null,
    remarks:'',
    status:'1'
  });
  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };
  useEffect(() => {
    fetchItems();
    fetch();
    fetchstateItems();
    
  }, []);


  /* const fetchItems = async () => {
    try {
      const tokens='1';
      console.log(selectedOption);
      console.log(selectedcattype);
      console.log(selectedlang);
      console.log(formData.adlocation);
      if (typeof formData === 'undefined') {
        formData = {};
      }
      if (selectedOption === undefined || selectedOption === 'undefined' || selectedcattype === '') {
        setSelectedOption( 0);  // Use '0' as a string for consistency
      }

      if (selectedcattype === undefined || selectedcattype === 'undefined'|| selectedcattype === '') {
        setselectedcattype ( 0);  // Use '0' as a string for consistency
      }
      if (selectedlang === undefined || selectedlang === 'undefined' || selectedlang === '') {
        setselectedlang(0);  // Use '0' as a string for consistency
      }
      // Handle the case where formData.adlocation is either undefined or the string 'undefined'
      if (formData.adlocation === undefined || formData.adlocation === 'undefined') {
        formData.adlocation = '0';  // Use '0' as a string for consistency
      }
      const response = await axios.get('https://api.myonventure.com/api/Advertisement/subcategoryadminsearch?id='+selectedOption +'&cattype='+selectedcattype+'&lang='+selectedlang+'&adlocation='+formData.adlocation+'&tokens='+tokens+'');
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  }; */

   const fetchItems = async () => {
    try {
      const tokens='1';
     /*  console.log(selectedOption);
      console.log(selectedcattype);
      console.log(selectedlang);
      console.log(formData.adlocation); */
      /* if (typeof formData === 'undefined') {
        formData = {};
      }
      if (selectedOption === undefined || selectedOption === 'undefined' || selectedcattype === '') {
        setSelectedOption( 0);  // Use '0' as a string for consistency
      }

      if (selectedcattype === undefined || selectedcattype === 'undefined'|| selectedcattype === '') {
        setselectedcattype ( 0);  // Use '0' as a string for consistency
      }
      if (selectedlang === undefined || selectedlang === 'undefined' || selectedlang === '') {
        setselectedlang(0);  // Use '0' as a string for consistency
      }
      // Handle the case where formData.adlocation is either undefined or the string 'undefined'
      if (formData.adlocation === undefined || formData.adlocation === 'undefined') {
        formData.adlocation = '0';  // Use '0' as a string for consistency
      } */
      const response = await axios.get('https://api.myonventure.com/api/Advertisement/advertisementall?tokens='+tokens+'');
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  }; 
  const fetchstateItems = async () => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/state/state?tokens='+tokens+'');
      setstateOptions(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const fetchcityItems = async (id) => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/city/Stateid?id='+id+'&tokens='+tokens+'');
      setcityOptions(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };
  const setstateData=async(id)=>{
    setstateSelectedOption(id);
    
    setFormData({ ...formData, state: id });
    fetchcityItems(id);
  };

  const setcityData=async(id)=>{
    setcitySelectedOption(id);
    
    setFormData({ ...formData, city: id });
  
  };

 const fetch=async()=>{
  
    const response1 = await axios.get('https://api.myonventure.com/api/Adlocation/Adlocationall?tokens=1');
        
          // Update state with fetched data
          setOptions(response1.data);
   };
  
  
  

   const handleAddItem = async () => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const postData = new FormData();
  
      // Populate FormData object with formData fields
      postData.append('pagelocation', formData.pagelocation);
      postData.append('companyname', formData.companyname);
      postData.append('amount', formData.amount);
      postData.append('state', formData.state);
      postData.append('city', formData.city);
      postData.append('country', formData.country);
      const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      postData.append('fromdate', format(new Date(formData.fromdate), 'dd-MM-yyyy'));
      postData.append('todate',format(new Date(formData.todate), 'dd-MM-yyyy'));
      
      // Handle file upload
      if (formData.Myfile) {
      
        postData.append('image', 'formData.Myfile');
        postData.append('Myfile', formData.Myfile);
      } else {
        // Optionally handle cases where no file is provided
        postData.append('image', ""); // or omit this line if not needed
        postData.append('Myfile', ""); // or omit this line if not needed
      }
  
      postData.append('remarks', formData.remarks);
      postData.append('status', formData.status);
  
      // Logging for debugging
      console.log('FormData:', formData);
  
      // Make the request
      const response = await axios.post(
        'https://api.myonventure.com/api/Advertisement/Insert',
        postData,
        {'headers': headers } // Updated headers syntax
      );
      
      // Log response data
      console.log('Response Data:', response.data);
  
      // Update the items and clear the form
      fetchItems();
      setItems([...items, response.data]);
  
      // Clear formData fields
      setFormData({
        id: '', pagelocation: '',
        companyname: '', amount: '', state: '', country: '1',
        city: '', fromdate: '',
        todate: '',
        image: '',
        Myfile: null,
        remarks: '',
        status: '1'
      });
  
      // Switch to read operation
      handleSwitchOperation('read');
    } catch (error) {
      console.error('Error adding item:', error.message); // Improved error logging
    }
  };
  

  const handleDeleteItem = async (id) => {
    try {
      await axios.get('https://api.myonventure.com/api/Advertisement/Delete?id='+id+'');
      setItems(items.filter(item => item.id !== id));
      handleSwitchOperation('read');
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleEditItem = async (dataitem1) => {
    try {
      const headers = {  'Content-Type': 'multipart/form-data'}  
      const body=JSON.stringify(formData);
    
      const postData = new FormData();
      postData.append('id', formData.id);
      postData.append('pagelocation', formData.pagelocation);
      postData.append('companyname', formData.companyname);
      postData.append('amount', formData.amount);
      postData.append('state', formData.state);
      postData.append('city', formData.city);
      postData.append('country', formData.country);
      postData.append('fromdate', format(new Date(formData.fromdate), 'dd-MM-yyyy'));
      postData.append('todate',format(new Date(formData.todate), 'dd-MM-yyyy'));
      if(formData.Myfile=='undefined')
        {
          postData.append('image', " ");
          postData.append('Myfile'," ");
        }else{
              postData.append('image', formData.Myfile);
              postData.append('Myfile',formData.Myfile);
                }
      postData.append('remarks', formData.remarks);
      postData.append('status', formData.status);
      
     console.log(postData);
      
          const response = await axios.post('https://api.myonventure.com/api/Advertisement/Update', postData,{'headers':headers});
         
          setEditingItemId(null);
          fetchItems();
          setItems([...items, response.data]);
          setFormData({ id: '',pagelocation: '' ,
            companyname:'',amount:'',state:'',country:'1',
            city:'',fromdate: '',
            todate:'',
            image:'',
            Myfile:null,
            remarks:'',
            status:'1' });
          handleSwitchOperation('read');
   //   setItems(items.map(item => item.id === id ? { ...item, formData } : item));
     
    } catch (error) {
      console.error('Error editing item:', error);
    }
  };

const showdatafact=async(id)=>{


}
  const showedititem=async(dataitem)=>{
    fetch();
    console.log(dataitem);
    setEditingItemId(dataitem.id);
    setSelectedOption(dataitem.pagelocation);
    setstateSelectedOption(dataitem.state);
    fetchcityItems(dataitem.state);
    setcitySelectedOption(dataitem.city);
    console.log(selectedOption);
   
  setimage(dataitem.image);
    setFormData({ id: dataitem.id,pagelocation: dataitem.pagelocation ,
      companyname: dataitem.companyname,amount: dataitem.amount,state: dataitem.state,
      city: dataitem.city,fromdate:format(new Date(dataitem.fromdate), 'dd-MM-yyyy'),
      todate: format(new Date(dataitem.todate), 'dd-MM-yyyy'),
      image: dataitem.Myfile,
      Myfile: dataitem.Myfile,
      remarks: dataitem.remarks,
      status: dataitem.status });
    
    handleSwitchOperation('Edit');
  };
const handlesearch=async()=>{
  fetchItems();

};
  const handleFileChange = (e) => {
    // Update the Myfile field in the formData state with the selected file object
    setFormData({ ...formData, Myfile: e.target.files[0] });
  };
  const setData=async(id)=>{
    setSelectedOption(id);
    
    setFormData({ ...formData, pagelocation: id })
  };

 
  return (
    <><Header>
          <div className="container-fluid">
                    {/*         <!-- Page Heading --> */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Advertisement</h1>
                    <button className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onClick={() => handleSwitchOperation('add')}><i
                        className="fas fa-plus fa-sm text-white-50"></i> Add New</button>
                     <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                        className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
                </div>

                    
             
                <div className="row">

                    {/*     <!-- Area Chart --> */}
                    <div className="col-lg-12">
                        <div className="card shadow mb-4">
                            {/*  <!-- Card Header - Dropdown --> */}
                            <div
                                className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">Advertisement View/Add/Update</h6>
                                <div className="dropdown no-arrow">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="dropdownMenuLink">
                                        <div className="dropdown-header">Dropdown Header:</div>
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                            {/*      <!-- Card Body --> */}
                            <div className="card-body">
                            <div>

                      
    <div>
       {/*  Read Operation Start  */}
       {crudOperation === 'read' && (
<div>
<div className='container-fluid p-2'>
          {/* <div className='form-inline'>
     <span>   Category <br/><select className='form-control'
           value={selectedOption}
           onChange={e =>  setData(e.target.value)}>
           <option value="0">Select an option</option>
           {options.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></span> &nbsp; <span>   Category type<br/> <select className='form-control'
           value={selectedcattype}
           onChange={e =>  setcattypeData(e.target.value)}>
           <option value="0">Select an option</option>
           {cattypeoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></span>&nbsp;<span> Language <br/> <select className='form-control'
           value={selectedlang}
           onChange={e =>  setlangData(e.target.value)}>
           <option value="0">Select an option</option>
           {langoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></span>&nbsp;<span> AD Location<br/> <select className='form-control'
           value={formData.adlocation}
           onChange={e => setFormData({ ...formData, adlocation: e.target.value })}>  
              <option value="0">Select an option</option>       
           <option value="INTER NATIONAL" key="INTER NATIONAL">INTER NATIONAL</option>
           <option value="NATIONAL" key="NATIONAL">NATIONAL</option>
           <option value="REGIONAL" key="REGIONAL">REGIONAL</option>
         </select></span> &nbsp;<span><br/> <button className='btn  btn-primary' onClick={() => handlesearch()}><i className='fa fa-search'></i> SEARCH</button> </span>
         </div> */}
          </div>  
    <table className='table table-bordered table-responsive'>
    <thead>
      <tr>
                <th>Action</th>
                <th>Sr. No.</th>
                <th>Ad Location</th>
                <th>Company Name</th>
                <th>State </th>
                <th>City </th>
                <th>From Date </th>
                <th>To Date </th>
                <th>Amount </th>
                <th>Remarks </th>
                <th>Image </th>
            </tr></thead> 
            {items.map((item, index) => {
             var dtl='';
               if (typeof item.remarks === 'string') {
               dtl=item.remarks.substring(0,50);
              } 
     return (
<tbody>
  <tr  key={index}>
      <td width={130} className='form-inline'>   
    
      <button className='btn btn-sm  btn-info  btn-block' onClick={() => showedititem(item)}>Edit</button>   

      <button className='btn btn-sm btn-danger btn-block' onClick={() => handleDeleteItem(item.id)}>Delete</button> 
      </td>
                <td>{index + 1}</td>
                <td>{item.adlocationname}</td>
                <td>{item.companyname}</td> 
                <td>{item.statename}</td>                
                <td>{item.city}</td> 
                <td>{format(new Date(item.fromdate), 'dd-MM-yyyy')}</td> 
                <td>{format(new Date(item.todate), 'dd-MM-yyyy')}</td> 
                <td>{item.amount}</td>
                  <td class='tooltip-test' title={item.remarks}>{dtl}...</td>
                <td><img height={50} width={75} className='img-responsive' src={'https://api.myonventure.com/api/../advertisement/'+item.image}/></td> 
            </tr>
            </tbody>
   ) })}
            
           </table></div>
           
       )}
           {/*  Read Operation END  */}
      
       {/*  Add Operation Start  */}
       {crudOperation === 'add' && (
           <div className='form-inline'>
<table className='table '>
  <tbody>

         <tr><td> Select Ad Location</td><td> <select className='form-control'
           value={selectedOption}
           onChange={e =>  setData(e.target.value)}>
           <option value="">Select an option</option>
           {options.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
        
        
  <tr><td>    Enter Company Name </td><td> <input className='form-control'
            type="text"  style={{width: '100%'}}
            placeholder="Name"
            value={formData.companyname}
            onChange={(e) => setFormData({ ...formData, companyname: e.target.value })}
          /></td></tr>
           <tr><td> Select State</td><td> <select className='form-control'
           value={selectedstateOption}
           onChange={e =>  setstateData(e.target.value)}>
           <option value="">Select an option</option>
           {stateoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
         <tr><td> Select City</td><td> <select className='form-control'
           value={selectedcityOption}
           onChange={e =>  setcityData(e.target.value)}>
           <option value="">Select an option</option>
           {cityoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
          
         <tr><td>    Enter from Date </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.fromdate}
            onChange={(e) => setFormData({ ...formData, fromdate: e.target.value })}
          /></td></tr> <tr><td>    Enter To Date </td><td> <input className='form-control'
          type="text"
          placeholder="To Date"
          value={formData.todate}
          onChange={(e) => setFormData({ ...formData, todate: e.target.value })}
        /></td></tr> <tr><td>    Enter Amount </td><td> <input className='form-control'
        type="text"
        placeholder="Amount"
        value={formData.amount}
        onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
      /></td></tr> 
     <tr><td>   Remarks</td><td> <textarea className='form-control'
          rows={10} style={{width: '100%'}}
            placeholder="Name"
            value={formData.remarks}
            onChange={(e) => setFormData({ ...formData, remarks: e.target.value })}
          /></td></tr>
     <tr><td>    Upload image </td><td>  <input className='form-control'
                type="file" name="Myfile"
                onChange={handleFileChange}
    /></td></tr> 
  <tr><td></td><td>   <button className='btn btn-info'  onClick={handleAddItem}>Save</button> 
       &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
      </td></tr></tbody>
</table>

            
     
        
           
   </div>)}  
         {/*  Add Operation END  */}
     
         {/*  Edit Operation START  */}      
    {crudOperation === 'Edit' && ( <div className='form-inline'>

    <table className='table '><tbody>
 
    <tr><td> Select Ad Location</td><td> <select className='form-control'
           value={selectedOption}
           onChange={e =>  setData(e.target.value)}>
           <option value="">Select an option</option>
           {options.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
        
        
  <tr><td>    Enter Company Name </td><td> <input className='form-control'
            type="text"  style={{width: '100%'}}
            placeholder="Name"
            value={formData.companyname}
            onChange={(e) => setFormData({ ...formData, companyname: e.target.value })}
          /></td></tr>
           <tr><td> Select State</td><td> <select className='form-control'
           value={selectedstateOption}
           onChange={e =>  setstateData(e.target.value)}>
           <option value="">Select an option</option>
           {stateoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
         <tr><td> Select City</td><td> <select className='form-control'
           value={selectedcityOption}
           onChange={e =>  setcityData(e.target.value)}>
           <option value="">Select an option</option>
           {cityoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
          
         <tr><td>    Enter from Date </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.fromdate}
            onChange={(e) => setFormData({ ...formData, fromdate: e.target.value })}
          /></td></tr> <tr><td>    Enter To Date </td><td> <input className='form-control'
          type="text"
          placeholder="To Date"
          value={formData.todate}
          onChange={(e) => setFormData({ ...formData, todate: e.target.value })}
        /></td></tr> <tr><td>    Enter Amount </td><td> <input className='form-control'
        type="text"
        placeholder="Amount"
        value={formData.amount}
        onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
      /></td></tr> 
     <tr><td>   Remarks</td><td> <textarea className='form-control'
          rows={10} style={{width: '100%'}}
            placeholder="Name"
            value={formData.remarks}
            onChange={(e) => setFormData({ ...formData, remarks: e.target.value })}
          /></td></tr>

      <tr><td >    Upload image </td><td className='form-inline'> <input className='form-control'
                type="file" name="Myfile"
                onChange={handleFileChange}
                
    />
 &nbsp;<div>  <img height={70} width={200} className='img-responsive' src={'https://api.myonventure.com/api/../Advertisement/'+image} />
  </div>   </td></tr>
  <tr><td></td><td>             <button className='btn btn-info' onClick={handleEditItem}>UPDATE</button>
              &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
      </td></tr></tbody>
</table>
   
    </div>
      )
    }
      {/*  Edit Operation END  */}  
    
      
    </div>
    </div>
                            </div>
                        </div>
                    </div>

                    </div>   
                </div>
    <Footer/></Header></>
  );
}

export default Advertisment;