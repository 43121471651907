import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../componants/header';
import Footer from '../../componants/footer';

function City() {
  const [crudOperation, setCrudOperation] = useState('read'); // Default to 'read'
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [stateoptions, setstateOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedstateOption, setSelectedstateOption] = useState('');
  const [formData, setFormData] = useState({
    id: '',
    name: '' ,
    state:'',
    country:'' 
  });
  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };
  useEffect(() => {
    fetchItems();
    fetch();
  }, []);


  const fetchItems = async () => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/City/city?tokens='+tokens+'');
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  
 const fetch=async()=>{
  
    const response1 = await axios.get('https://api.myonventure.com/api/Country/country?tokens=1');
        
          // Update state with fetched data
          setOptions(response1.data);
   };
  
   const statefetch=async(id)=>{
    
      const response1 = await axios.get('https://api.myonventure.com/api/State/Stateid?id='+id+'');
          
            // Update state with fetched data
            setstateOptions(response1.data);
     };
  

  const handleAddItem = async () => {
    try {
      
      const headers = { 'content-type': 'application/json'}  
  const body=JSON.stringify(formData);

  const postData = new FormData();
  postData.append('country', formData.country);
  postData.append('name', formData.name);
  postData.append('state', formData.state);
  
      const response = await axios.post('https://api.myonventure.com/api/City/Insert',  postData,{'headers':headers});
      console.log(postData);
      fetchItems();
      setItems([...items, response.data]);
      setFormData({ id: '', name: '',state:'',country:'' });
      handleSwitchOperation('read');
      /* setNewItemText(''); */
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await axios.get('https://api.myonventure.com/api/City/Delete?id='+id+'');
      setItems(items.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleEditItem = async (dataitem1) => {
    try {
      const headers = { 'content-type': 'application/json'}  
      const body=JSON.stringify(formData);
    
      const postData = new FormData();
      postData.append('id', formData.id);
      postData.append('name', formData.name);
      postData.append('state', formData.state);
      postData.append('country', formData.country);
          const response = await axios.post('https://api.myonventure.com/api/City/Update', postData,{'headers':headers});
          console.log(postData); 
          setEditingItemId(null);
          fetchItems();
          setItems([...items, response.data]);
          setFormData({ id: '', name: '',state:'',country:'' });
          handleSwitchOperation('read');
   //   setItems(items.map(item => item.id === id ? { ...item, formData } : item));
     
    } catch (error) {
      console.error('Error editing item:', error);
    }
  };


  const showedititem=async(dataitem)=>{
    fetch();
    console.log(dataitem);
    setEditingItemId(dataitem.id);
    setSelectedOption(dataitem.country);
    statefetch(dataitem.country);
    setSelectedstateOption(dataitem.state);
    console.log(selectedOption);
    console.log(dataitem.country);
    console.log(selectedOption);
    setFormData({ id: dataitem.id, name: dataitem.name,state:dataitem.state ,country:dataitem.country});
    
    handleSwitchOperation('Edit');
  };

  const showstate=async(id)=>{
    
    setSelectedOption(id);
    console.log(id);
    statefetch(id);
    setFormData({ ...formData, country: id })
  };
  const setstateData=async(id)=>{
    setSelectedstateOption(id);
    
    setFormData({ ...formData, state: id })
  };
  return (
    <><Header>
          <div className="container-fluid">
                    {/*         <!-- Page Heading --> */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">City</h1>
                    <button className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onClick={() => handleSwitchOperation('add')}><i
                        className="fas fa-plus fa-sm text-white-50"></i> Add New</button>
                     <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                        className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
                </div>

                    
             
                <div className="row">

                    {/*     <!-- Area Chart --> */}
                    <div className="col-lg-12">
                        <div className="card shadow mb-4">
                            {/*  <!-- Card Header - Dropdown --> */}
                            <div
                                className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">City View/Add/Update</h6>
                                <div className="dropdown no-arrow">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="dropdownMenuLink">
                                        <div className="dropdown-header">Dropdown Header:</div>
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                            {/*      <!-- Card Body --> */}
                            <div className="card-body">
                            <div>

                          
    <div>
       {/*  Read Operation Start  */}
       {crudOperation === 'read' && (
    <table className='table table-bordered'>
    <thead><tr>
                <th>Action</th>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>State </th>
                <th>Country</th>
            </tr></thead> 
            {items.map((item, index) => {
     return (
<tbody><tr  key={index}>
                <td style={{width:'auto'}}>    <button className='btn btn-sm btn-danger' onClick={() => handleDeleteItem(item.id)}>Delete</button>
         &nbsp;   <button  className='btn btn-sm  btn-info' onClick={() => showedititem(item)}>Edit</button></td>
                <td>{index + 1}</td>
                <td>{item.name}</td> 
                <td>{item.statename}</td> 
                <td>{item.countryname}</td> 
            </tr></tbody>
   ) })}
            
           </table>
       )}
           {/*  Read Operation END  */}
      
       {/*  Add Operation Start  */}
       {crudOperation === 'add' && (
           <div className='form-inline'>
<table className='table '>
  <tbody>
  <tr><td> Select Country</td><td> <select className='form-control'
           value={selectedOption}
           onChange={e =>  showstate( e.target.value )}>
           <option value="">Select an option</option>
           {options.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
         <tr><td> Select State</td><td> <select className='form-control'
           value={selectedstateOption}
           onChange={e =>  setstateData(e.target.value)}>
           <option value="">Select an option</option>
           {stateoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
  <tr><td>    Enter City Name </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          /></td></tr>
        
  <tr><td></td><td>   <button className='btn btn-info'  onClick={handleAddItem}>Save</button> 
       &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
      </td></tr></tbody>
</table>

            
     
        
           
   </div>)}  
         {/*  Add Operation END  */}
     
         {/*  Edit Operation START  */}      
    {crudOperation === 'Edit' && ( <div className='form-inline'>

    <table className='table '><tbody>
  <tr><td> Select Country</td><td> <select className='form-control'
           value={selectedOption}
           onChange={e =>  showstate( e.target.value )}>
           <option value="">Select an option</option>
           {options.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
         <tr><td> Select State</td><td> <select className='form-control'
           value={selectedstateOption}
           onChange={e =>  setstateData(e.target.value)}>
           <option value="">Select an option</option>
           {stateoptions.map(option => (
             <option key={option.id} value={option.id}>
               {option.name}
             </option>
           ))}
         </select></td></tr>
  <tr><td>    Enter City Name </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          /></td></tr>
         
  <tr><td></td><td>             <button className='btn btn-info' onClick={handleEditItem}>Update Item</button>
              &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
      </td></tr></tbody>
</table>
   
    </div>
      )
    }
      {/*  Edit Operation END  */}  
    
      
    </div>
    </div>
                            </div>
                        </div>
                    </div>

                    </div>   
                </div>
    <Footer/></Header></>
  );
}

export default City;