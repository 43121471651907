import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Link } from "react-router-dom";
import './footer.css';


function Footer()
{
    return(
       
         <footer class="sticky-footer bg-white">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Your Website 2021</span>
                    </div>
                </div>
            </footer>
       
        
        );

 }

    
export default Footer
