import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../componants/header";
import Footer from "../../componants/footer";
import Category from "../Category/Category";
import { Link } from "react-router-dom";

function Subcategory() {
  const [crudOperation, setCrudOperation] = useState("read"); // Default to 'read'
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const [selectedcattype, setselectedcattype] = useState("");
  const [cattypeoptions, setcattypeOptions] = useState([]);

  const [selectedlang, setselectedlang] = useState("");
  const [langoptions, setlangOptions] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setimage] = useState("");

  const [stateoptions, setstateOptions] = useState([]);
  const [selectedstateOption, setstateSelectedOption] = useState("");

  const [cityoptions, setcityOptions] = useState([]);
  const [selectedcityOption, setcitySelectedOption] = useState("");

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    state: "",
    city: "",
    category: "",
    categorytype: "",
    adlanguage: "",
    image: "",
    Myfile: null,
    details: "",
    topquestion1: "",
    topans1: "",
    topquestion2: "",
    topans2: "",
    keyword: "",
  });
  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };
  useEffect(() => {
    // fetchItems();
    fetch();
    fetchcategorytype();
    fetchlang();
    fetchstateItems();
  }, []);

  const fetchItems = async () => {
    try {
      const tokens = "1";
      console.log(selectedOption);
      console.log(selectedcattype);
      console.log(selectedlang);
      console.log(formData.adlocation);
      if (typeof formData === "undefined") {
        formData = {};
      }
      if (
        selectedOption === undefined ||
        selectedOption === "undefined" ||
        selectedcattype === ""
      ) {
        setSelectedOption(0); // Use '0' as a string for consistency
      }

      if (
        selectedcattype === undefined ||
        selectedcattype === "undefined" ||
        selectedcattype === ""
      ) {
        setselectedcattype(0); // Use '0' as a string for consistency
      }

      if (
        selectedstateOption === undefined ||
        selectedstateOption === "undefined" ||
        selectedstateOption === ""
      ) {
        setstateSelectedOption(0); // Use '0' as a string for consistency
      }
      if (
        selectedcityOption === undefined ||
        selectedcityOption === "undefined" ||
        selectedcityOption === ""
      ) {
        setcitySelectedOption(0); // Use '0' as a string for consistency
      }
      if (
        selectedlang === undefined ||
        selectedlang === "undefined" ||
        selectedlang === ""
      ) {
        setselectedlang(0); // Use '0' as a string for consistency
      }
      // Handle the case where formData.adlocation is either undefined or the string 'undefined'
      if (
        formData.adlocation === undefined ||
        formData.adlocation === "undefined"
      ) {
        formData.adlocation = "0"; // Use '0' as a string for consistency
      }
      const response = await axios.get(
        "https://api.myonventure.com/api/SubCategory/subcategoryadminsearch?id=" +
          selectedOption +
          "&cattype=" +
          selectedcattype +
          "&lang=" +
          selectedlang +
          "&adlocation=" +
          formData.adlocation +
          "&state=" +
          selectedstateOption +
          "&city=" +
          selectedcityOption +
          "&tokens=" +
          tokens +
          ""
      );
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchcategorytype = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Categorytype/categorytypeall?tokens=" +
          tokens
      );
      // Ensure the response is an array
      if (Array.isArray(response.data)) {
        setcattypeOptions(response.data);
      } else {
        console.error(
          "Expected an array of category types, but got:",
          response.data
        );
        setcattypeOptions([]); // Set to empty array if the data is not an array
      }
    } catch (error) {
      console.error("Error fetching category types:", error);
    }
  };

  const fetchlang = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Adlanguage/languageall?tokens=" +
          tokens +
          ""
      );
      setlangOptions(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetch = async () => {
    const response1 = await axios.get(
      "https://api.myonventure.com/api/Categories/categoryall?tokens=1"
    );

    // Update state with fetched data
    setOptions(response1.data);
  };

  const fetchstateItems = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/state/state?tokens=" + tokens + ""
      );
      setstateOptions(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchcityItems = async (id) => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/city/Stateid?id=" +
          id +
          "&tokens=" +
          tokens +
          ""
      );
      setcityOptions(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const handleAddItem = async () => {
    try {
      const headers = { "Content-Type": "multipart/form-data" };
      const postData = new FormData();

      // Populate FormData object with formData fields
      postData.append("adlocation", formData.adlocation);
      postData.append("name", formData.name);
      postData.append("state", formData.state);
      postData.append("city", formData.city);
      postData.append("category", formData.category);
      postData.append("categorytype", formData.categorytype);
      postData.append("adlanguage", formData.adlanguage);

      postData.append("image", formData.Myfile);
      postData.append("Myfile", formData.Myfile);
      postData.append("details", formData.details);
      postData.append("topquestion1", formData.topquestion1);
      postData.append("topans1", formData.topans1);
      postData.append("topquestion2", formData.topquestion2);
      postData.append("topans2", formData.topans2);
      postData.append("keyword", formData.keyword);

      console.log(formData.Myfile);
      const response = await axios.post(
        "https://api.myonventure.com/api/SubCategory/Insert",
        postData,
        { headers: headers }
      );

      console.log(response.data); // Log response data
      fetchItems();
      setItems([...items, response.data]);

      // Clear formData fields
      setFormData({
        id: "",
        adlocation: "",
        name: "",
        state: "",
        city: "",
        category: "",
        categorytype: "",
        adlanguage: "",
        image: "",
        Myfile: null,
        details: "",
        topquestion1: "",
        topans1: "",
        topquestion2: "",
        topans2: "",
        keyword: "",
      });

      handleSwitchOperation("read");
    } catch (error) {
      console.error("Error adding item:", error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await axios.get(
        "https://api.myonventure.com/api/SubCategory/Delete?id=" + id + ""
      );
      setItems(items.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleEditItem = async (dataitem1) => {
    try {
      const headers = { "Content-Type": "multipart/form-data" };
      const body = JSON.stringify(formData);
      console.log(formData.Myfile);
      const postData = new FormData();
      postData.append("id", formData.id);
      postData.append("adlocation", formData.adlocation);
      postData.append("name", formData.name);
      postData.append("state", formData.state);
      postData.append("city", formData.city);
      postData.append("category", formData.category);
      postData.append("categorytype", formData.categorytype);
      console.log(formData.categorytype);
      postData.append("adlanguage", formData.adlanguage);
      if (formData.Myfile == "undefined") {
        postData.append("image", " ");
        postData.append("Myfile", " ");
      } else {
        postData.append("image", formData.Myfile);
        postData.append("Myfile", formData.Myfile);
      }
      console.log(formData.Myfile);
      postData.append("details", formData.details);
      postData.append("topquestion1", formData.topquestion1);
      postData.append("topans1", formData.topans1);
      postData.append("topquestion2", formData.topquestion2);
      postData.append("topans2", formData.topans2);
      postData.append("keyword", formData.keyword);

      const response = await axios.post(
        "https://api.myonventure.com/api/SubCategory/Update",
        postData,
        { headers: headers }
      );

      setEditingItemId(null);
      fetchItems();
      setItems([...items, response.data]);
      setFormData({
        id: "",
        adlocation: "",
        name: "",
        state: "",
        city: "",
        category: "",
        categorytype: "",
        adlanguage: "",
        image: "",
        Myfile: null,
        details: "",
        topquestion1: "",
        topans1: "",
        topquestion2: "",
        topans2: "",
        keyword: "",
      });
      handleSwitchOperation("read");
      //   setItems(items.map(item => item.id === id ? { ...item, formData } : item));
    } catch (error) {
      console.error("Error editing item:", error);
    }
  };

  const showdatafact = async (id) => {};
  const showedititem = async (dataitem) => {
    fetch();
    console.log(dataitem);
    setEditingItemId(dataitem.id);
    setstateSelectedOption(dataitem.state);
    fetchcityItems(dataitem.state);
    setcitySelectedOption(dataitem.city);
    fetchcityItems(dataitem.state);
    setSelectedOption(dataitem.category);
    setselectedcattype(dataitem.categorytype);
    setselectedlang(dataitem.adlanguage);
    console.log(selectedOption);

    setimage(dataitem.image);
    setFormData({
      id: dataitem.id,
      adlocation: dataitem.adlocation,
      name: dataitem.name,
      state: dataitem.state,
      city: dataitem.city,
      category: dataitem.category,
      categorytype: dataitem.categorytype,
      adlanguage: dataitem.adlanguage,
      image: dataitem.Myfile,
      Myfile: dataitem.Myfile,
      details: dataitem.details,
      topquestion1: dataitem.topquestion1,
      topans1: dataitem.topans1,
      topquestion2: dataitem.topquestion2,
      topans2: dataitem.topans2,
      keyword: dataitem.keyword,
    });

    handleSwitchOperation("Edit");
  };
  const handlesearch = async () => {
    fetchItems();
  };
  const handleFileChange = (e) => {
    // Update the Myfile field in the formData state with the selected file object
    setFormData({ ...formData, Myfile: e.target.files[0] });
  };
  const setData = async (id) => {
    setSelectedOption(id);

    setFormData({ ...formData, category: id });
  };

  const setcattypeData = async (id) => {
    setselectedcattype(id);

    setFormData({ ...formData, categorytype: id });
  };
  const setlangData = async (id) => {
    setselectedlang(id);

    setFormData({ ...formData, adlanguage: id });
  };
  const setstateData = async (id) => {
    setstateSelectedOption(id);

    setFormData({ ...formData, state: id });
    fetchcityItems(id);
  };

  const setcityData = async (id) => {
    setcitySelectedOption(id);

    setFormData({ ...formData, city: id });
  };
  return (
    <>
      <Header>
        <div className="container-fluid">
          {/*         <!-- Page Heading --> */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Sub Category</h1>
            <button
              className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              onClick={() => handleSwitchOperation("add")}
            >
              <i className="fas fa-plus fa-sm text-white-50"></i> Add New
            </button>
            <a
              href="#"
              className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            >
              <i className="fas fa-download fa-sm text-white-50"></i> Generate
              Report
            </a>
          </div>

          <div className="row">
            {/*     <!-- Area Chart --> */}
            <div className="col-lg-12">
              <div className="card shadow mb-4">
                {/*  <!-- Card Header - Dropdown --> */}
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Sub Category View/Add/Update
                  </h6>
                  <div className="dropdown no-arrow">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <div className="dropdown-header">Dropdown Header:</div>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                {/*      <!-- Card Body --> */}
                <div className="card-body">
                  <div>
                    <div>
                      {/*  Read Operation Start  */}
                      {crudOperation === "read" && (
                        <div>
                          <div className="container-fluid p-2">
                            <div className="form-inline">
                              <span>
                                {" "}
                                Select State
                                <br />
                                <select
                                  className="form-control"
                                  value={selectedstateOption}
                                  onChange={(e) => setstateData(e.target.value)}
                                >
                                  <option value="">Select an option</option>
                                  {stateoptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </span>
                              <span>
                                {" "}
                                Select City
                                <br />
                                <select
                                  className="form-control"
                                  value={selectedcityOption}
                                  onChange={(e) => setcityData(e.target.value)}
                                >
                                  <option value="">Select an option</option>
                                  {cityoptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </span>
                              <span>
                                {" "}
                                Category <br />
                                <select
                                  className="form-control"
                                  value={selectedOption}
                                  onChange={(e) => setData(e.target.value)}
                                >
                                  <option value="0">Select an option</option>
                                  {options.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </span>{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                Category type
                                <br />{" "}
                                <select
                                  className="form-control"
                                  value={selectedcattype}
                                  onChange={(e) =>
                                    setcattypeData(e.target.value)
                                  }
                                >
                                  <option value="0">Select an option</option>
                                  {cattypeoptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </span>
                              &nbsp;
                              <span>
                                {" "}
                                Language <br />{" "}
                                <select
                                  className="form-control"
                                  value={selectedlang}
                                  onChange={(e) => setlangData(e.target.value)}
                                >
                                  <option value="0">Select an option</option>
                                  {langoptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </span>
                              &nbsp;
                              <span>
                                {" "}
                                AD Location
                                <br />{" "}
                                <select
                                  className="form-control"
                                  value={formData.adlocation}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      adlocation: e.target.value,
                                    })
                                  }
                                >
                                  <option value="0">Select an option</option>
                                  <option
                                    value="INTER NATIONAL"
                                    key="INTER NATIONAL"
                                  >
                                    INTER NATIONAL
                                  </option>
                                  <option value="NATIONAL" key="NATIONAL">
                                    NATIONAL
                                  </option>
                                  <option value="REGIONAL" key="REGIONAL">
                                    REGIONAL
                                  </option>
                                </select>
                              </span>{" "}
                              &nbsp;
                              <span>
                                <br />{" "}
                                <button
                                  className="btn  btn-primary"
                                  onClick={() => handlesearch()}
                                >
                                  <i className="fa fa-search"></i> SEARCH
                                </button>{" "}
                              </span>
                            </div>
                          </div>
                          <table className="table table-bordered table-responsive">
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Sr. No.</th>
                                <th>Ad Location</th>
                                <th>Name</th>
                                <th>City </th>
                                <th>State </th>
                                <th>Category </th>
                                <th>Details </th>
                                <th>Head 1 </th>
                                <th>Head 1 Ans </th>
                                <th>Head 2 </th>
                                <th>Head 2 Ans </th>
                                <th>Keyword </th>
                                <th>Image </th>
                              </tr>
                            </thead>
                            {items.map((item, index) => {
                              var dtl = "";
                              if (typeof item.details === "string") {
                                dtl = item.details.substring(0, 50);
                              }

                              var dtlkeyword = "";
                              if (typeof item.keyword === "string") {
                                dtlkeyword = item.keyword.substring(0, 50);
                              }
                              return (
                                <tbody>
                                  <tr key={index}>
                                    <td width={130} className="form-inline">
                                      <button
                                        className="btn btn-sm  btn-info  btn-block"
                                        onClick={() => showedititem(item)}
                                      >
                                        Edit
                                      </button>
                                      <Link
                                        className="btn btn-sm btn-info  btn-block"
                                        to={{
                                          pathname: "/datafacts",
                                          search:
                                            "?value=" +
                                            item.id +
                                            "&name=" +
                                            item.name +
                                            "",
                                        }}
                                      >
                                        {" "}
                                        Data & facts{" "}
                                      </Link>
                                      <Link
                                        className="btn btn-sm btn-success  btn-block"
                                        to={{
                                          pathname: "/products",
                                          search:
                                            "?value=" +
                                            item.id +
                                            "&name=" +
                                            item.name +
                                            "",
                                        }}
                                      >
                                        {" "}
                                        Product{" "}
                                      </Link>
                                      {/*    <button className='btn btn-sm btn-danger btn-block' onClick={() => handleDeleteItem(item.id)}>Delete</button>  */}
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>{item.adlocation}</td>
                                    <td>{item.name}</td>
                                    <td>{item.cityname}</td>
                                    <td>{item.statename}</td>
                                    <td>{item.categoryname}</td>
                                    <td
                                      class="tooltip-test"
                                      title={item.details}
                                    >
                                      {dtl}...
                                    </td>
                                    <td>{item.topquestion1}</td>
                                    <td>{item.topans1}</td>
                                    <td>{item.topquestion2}</td>
                                    <td>{item.topans2}</td>
                                    <td
                                      class="tooltip-test"
                                      title={item.keyword}
                                    >
                                      {dtlkeyword}...
                                    </td>
                                    <td>
                                      <img
                                        height={50}
                                        width={75}
                                        className="img-responsive"
                                        src={
                                          "https://api.myonventure.com/api/../images/" +
                                          item.image
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </table>
                        </div>
                      )}
                      {/*  Read Operation END  */}

                      {/*  Add Operation Start  */}
                      {crudOperation === "add" && (
                        <div className="form-inline">
                          <table className="table ">
                            <tbody>
                              <tr>
                                <td> Select State</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={selectedstateOption}
                                    onChange={(e) =>
                                      setstateData(e.target.value)
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    {stateoptions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> Select City</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={selectedcityOption}
                                    onChange={(e) =>
                                      setcityData(e.target.value)
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    {cityoptions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> Select Category</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={selectedOption}
                                    onChange={(e) => setData(e.target.value)}
                                  >
                                    <option value="">Select an option</option>
                                    {options.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> Select Category type</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={selectedcattype}
                                    onChange={(e) =>
                                      setcattypeData(e.target.value)
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    {cattypeoptions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> Select Language</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={selectedlang}
                                    onChange={(e) =>
                                      setlangData(e.target.value)
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    {langoptions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> AD Location</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={formData.adlocation}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        adlocation: e.target.value,
                                      })
                                    }
                                  >
                                    <option
                                      value="INTER NATIONAL"
                                      key="INTER NATIONAL"
                                    >
                                      INTER NATIONAL
                                    </option>
                                    <option value="NATIONAL" key="NATIONAL">
                                      NATIONAL
                                    </option>
                                    <option value="REGIONAL" key="REGIONAL">
                                      REGIONAL
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> Enter Subcategory Name </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    style={{ width: "100%" }}
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        name: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td> Description</td>
                                <td>
                                  {" "}
                                  <textarea
                                    className="form-control"
                                    rows={10}
                                    style={{ width: "100%" }}
                                    placeholder="Name"
                                    value={formData.details}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        details: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td> Enter Head1 Name </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    value={formData.topquestion1}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        topquestion1: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>{" "}
                              <tr>
                                <td> Enter Head1 Ans </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    value={formData.topans1}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        topans1: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>{" "}
                              <tr>
                                <td> Enter Head2 Name </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    value={formData.topquestion2}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        topquestion2: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>{" "}
                              <tr>
                                <td> Enter Head2 Ans </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    value={formData.topans2}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        topans2: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td> Enter Keyword </td>
                                <td>
                                  {" "}
                                  <textarea
                                    className="form-control"
                                    rows={10}
                                    style={{ width: "100%" }}
                                    placeholder="Keyword"
                                    value={formData.keyword}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        keyword: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td> Upload image </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="file"
                                    name="Myfile"
                                    onChange={handleFileChange}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>
                                  {" "}
                                  <button
                                    className="btn btn-info"
                                    onClick={handleAddItem}
                                  >
                                    Save
                                  </button>
                                  &nbsp;{" "}
                                  <button
                                    className="btn btn-info"
                                    onClick={() =>
                                      handleSwitchOperation("read")
                                    }
                                  >
                                    Exit
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      {/*  Add Operation END  */}

                      {/*  Edit Operation START  */}
                      {crudOperation === "Edit" && (
                        <div className="form-inline">
                          <table className="table ">
                            <tbody>
                              <tr>
                                <td> Select State</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={selectedstateOption}
                                    onChange={(e) =>
                                      setstateData(e.target.value)
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    {stateoptions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> Select City</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={selectedcityOption}
                                    onChange={(e) =>
                                      setcityData(e.target.value)
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    {cityoptions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> Select Category</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={selectedOption}
                                    onChange={(e) => setData(e.target.value)}
                                  >
                                    <option value="">Select an option</option>
                                    {options.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> Select Category type</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={selectedcattype}
                                    onChange={(e) =>
                                      setcattypeData(e.target.value)
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    {cattypeoptions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> Select Language</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={selectedlang}
                                    onChange={(e) =>
                                      setlangData(e.target.value)
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    {langoptions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> AD Location</td>
                                <td>
                                  {" "}
                                  <select
                                    className="form-control"
                                    value={formData.adlocation}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        adlocation: e.target.value,
                                      })
                                    }
                                  >
                                    <option
                                      value="INTER NATIONAL"
                                      key="INTER NATIONAL"
                                    >
                                      INTER NATIONAL
                                    </option>
                                    <option value="NATIONAL" key="NATIONAL">
                                      NATIONAL
                                    </option>
                                    <option value="REGIONAL" key="REGIONAL">
                                      REGIONAL
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td> Enter Sub Category Name </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    style={{ width: "100%" }}
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        name: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td> Description</td>
                                <td>
                                  {" "}
                                  <textarea
                                    className="form-control"
                                    rows={10}
                                    style={{ width: "100%" }}
                                    placeholder="Name"
                                    value={formData.details}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        details: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td> Enter Head1 Name </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    value={formData.topquestion1}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        topquestion1: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>{" "}
                              <tr>
                                <td> Enter Head1 Ans </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    value={formData.topans1}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        topans1: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>{" "}
                              <tr>
                                <td> Enter Head2 Name </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    value={formData.topquestion2}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        topquestion2: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>{" "}
                              <tr>
                                <td> Enter Head2 Ans </td>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    value={formData.topans2}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        topans2: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td> Enter Keyword </td>
                                <td>
                                  {" "}
                                  <textarea
                                    className="form-control"
                                    rows={10}
                                    style={{ width: "100%" }}
                                    placeholder="Keyword"
                                    value={formData.keyword}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        keyword: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td> Upload image </td>
                                <td className="form-inline">
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="file"
                                    name="Myfile"
                                    onChange={handleFileChange}
                                  />
                                  &nbsp;
                                  <div>
                                    {" "}
                                    <img
                                      height={70}
                                      width={100}
                                      className="img-responsive"
                                      src={
                                        "https://api.myonventure.com/api/../images/" +
                                        image
                                      }
                                    />
                                  </div>{" "}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>
                                  {" "}
                                  <button
                                    className="btn btn-info"
                                    onClick={handleEditItem}
                                  >
                                    UPDATE
                                  </button>
                                  &nbsp;{" "}
                                  <button
                                    className="btn btn-info"
                                    onClick={() =>
                                      handleSwitchOperation("read")
                                    }
                                  >
                                    Exit
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      {/*  Edit Operation END  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* data & facts action modal */}

        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Modal title
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">...</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary">
                  Understood
                </button>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </Header>
    </>
  );
}

export default Subcategory;
