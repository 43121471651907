import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../componants/header';
import Footer from '../../componants/footer';
import Category from '../Category/Category';


function Agencylogo() {
  const [crudOperation, setCrudOperation] = useState('read'); // Default to 'read'
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
   
    image:'',Myfile: null,
    
  });
  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };
  useEffect(() => {
    fetchItems();
   
  }, []);


  const fetchItems = async () => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/Agencylogo/agencylogoall?tokens='+tokens+'');
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  
 
  
  
  

   const handleAddItem = async () => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const postData = new FormData();
      
      // Populate FormData object with formData fields
     
      postData.append('image', formData.Myfile);
      postData.append('Myfile', formData.Myfile);
     
      console.log(formData.Myfile);
      const response = await axios.post(
        'https://api.myonventure.com/api/Agencylogo/Insert',
        postData,
        { headers: headers }
      );
      
      console.log(response.data); // Log response data
      fetchItems();
      setItems([...items, response.data]);
      
      // Clear formData fields
      setFormData({
        id: '',
        
        image: '',
        Myfile: null,
       
      });
      
      handleSwitchOperation('read');
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await axios.get('https://api.myonventure.com/api/Agencylogo/Delete?id='+id+'');
      setItems(items.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleEditItem = async (dataitem1) => {
    try {
      const headers = {  'Content-Type': 'multipart/form-data'}  
      const body=JSON.stringify(formData);
      console.log(formData.Myfile); 
      const postData = new FormData();
      postData.append('id', formData.id);
     
      if(formData.Myfile=='undefined')
{
  postData.append('image', "");
  postData.append('Myfile',"");
}else{
      postData.append('image', formData.Myfile);
      postData.append('Myfile',formData.Myfile);}
     
      
          const response = await axios.post('https://api.myonventure.com/api/Agencylogo/Update', postData,{'headers':headers});
         
          setEditingItemId(null);
          fetchItems();
          setItems([...items, response.data]);
          setFormData({ id: '',image:'',Myfile: null });
          handleSwitchOperation('read');
   //   setItems(items.map(item => item.id === id ? { ...item, formData } : item));
     
    } catch (error) {
      console.error('Error editing item:', error);
    }
  };


  const showedititem=async(dataitem)=>{
    fetch();
    console.log(dataitem);
    setEditingItemId(dataitem.id);
    

  
    setFormData({ id: dataitem.id,image:dataitem.Myfile,
    Myfile:dataitem.Myfile});
    
    handleSwitchOperation('Edit');
  };

  const handleFileChange = (e) => {
    // Update the Myfile field in the formData state with the selected file object
    setFormData({ ...formData, Myfile: e.target.files[0] });
  };
  const setData=async(id)=>{
    setSelectedOption(id);
    
    setFormData({ ...formData, category: id })
  };
  return (
    <><Header>
          <div className="container-fluid">
                    {/*         <!-- Page Heading --> */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Agency Logo</h1>
                    <button className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onClick={() => handleSwitchOperation('add')}><i
                        className="fas fa-plus fa-sm text-white-50"></i> Add New</button>
                     <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                        className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
                </div>

                    
             
                <div className="row">

                    {/*     <!-- Area Chart --> */}
                    <div className="col-lg-12">
                        <div className="card shadow mb-4">
                            {/*  <!-- Card Header - Dropdown --> */}
                            <div
                                className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">Client Logo View/Add/Update</h6>
                                <div className="dropdown no-arrow">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="dropdownMenuLink">
                                        <div className="dropdown-header">Dropdown Header:</div>
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                            {/*      <!-- Card Body --> */}
                            <div className="card-body">
                            <div>

                          
    <div>
       {/*  Read Operation Start  */}
       {crudOperation === 'read' && (
    <table className='table table-bordered'>
    <thead><tr>
                <th>Action</th>
                <th>Sr. No.</th>
           
                <th>Image </th>
            </tr></thead> 
            {items.map((item, index) => {
     return (
<tbody><tr  key={index}>
                <td width={130} className='form-inline'>    <button className='btn btn-sm btn-danger' onClick={() => handleDeleteItem(item.id)}>Delete</button>
         &nbsp;   <button  className='btn btn-sm  btn-info' onClick={() => showedititem(item)}>Edit</button></td>
                <td>{index + 1}</td>
                
                <td><img height={50} width={75} className='img-responsive' src={'https://api.myonventure.com/api/../agencylogo/'+item.image}/></td> 
            </tr></tbody>
   ) })}
            
           </table>
       )}
           {/*  Read Operation END  */}
      
       {/*  Add Operation Start  */}
       {crudOperation === 'add' && (
           <div className='form-inline'>
<table className='table '>
  <tbody>

        
     <tr><td>    Upload image </td><td>  <input className='form-control'
                type="file" name="Myfile"
                onChange={handleFileChange}
    /></td></tr> 
  <tr><td></td><td>   <button className='btn btn-info'  onClick={handleAddItem}>Save</button> 
       &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
      </td></tr></tbody>
</table>

            
     
        
           
   </div>)}  
         {/*  Add Operation END  */}
     
         {/*  Edit Operation START  */}      
    {crudOperation === 'Edit' && ( <div className='form-inline'>

    <table className='table '><tbody>
 
         
      <tr><td>    Upload image </td><td> <input className='form-control'
                type="file" name="Myfile"
                onChange={handleFileChange}
    /></td></tr>
  <tr><td></td><td>             <button className='btn btn-info' onClick={handleEditItem}>Update Item</button>
              &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
      </td></tr></tbody>
</table>
   
    </div>
      )
    }
      {/*  Edit Operation END  */}  
    
      
    </div>
    </div>
                            </div>
                        </div>
                    </div>

                    </div>   
                </div>
    <Footer/></Header></>
  );
}

export default Agencylogo;