import React,{ useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import './Login.css';
import Dashboard from './Pages/Dashboard/Dashboard';
function Logout() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
 
    useEffect(()=>{
        if(localStorage.getItem('token') != "" && localStorage.getItem('token') != null){
          
          
           navigate("/Login");
            setIsSubmitting(true);
             localStorage.setItem('token', '');
             localStorage.setItem('adminuser', '');
        }
        console.log(localStorage.getItem('token'))
    },[])
 

}

export default Logout;