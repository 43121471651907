import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../componants/header';
import Footer from '../../componants/footer';
import Category from '../Category/Category';


function Productimage({id}) {
  
  const [crudOperation, setCrudOperation] = useState('read'); // Default to 'read'
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    productid:'',
    image:'',
    Myfile: null
    
  });
  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };
  useEffect(() => {
    fetchItems();
    setCrudOperation('Add');
  }, []);


  const fetchItems = async () => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/Product/productimageall?id='+id+'&tokens='+tokens+'');
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

   const handleAddItem = async () => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const postData = new FormData();
      
      // Populate FormData object with formData fields
  
      postData.append('productid', id);
      postData.append('image', formData.Myfile);
      postData.append('Myfile', formData.Myfile);
     
      console.log(formData.Myfile);
      const response = await axios.post(
        'https://api.myonventure.com/api/Product/ProductImageInsert',
        postData,
        { headers: headers }
      );
      setFormData({
        id: '',      
        productid: '',
        image: '',
        Myfile: '',
        
      });
      console.log(response.data); // Log response data
      fetchItems();
      setItems([...items, response.data]);
      
      // Clear formData fields
      
      
      handleSwitchOperation('read');
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await axios.get('https://api.myonventure.com/api/Product/ProductImageDelete?id='+id+'');
      setItems(items.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleEditItem = async (dataitem1) => {
    try {
      const headers = {  'Content-Type': 'multipart/form-data'}  
      const body=JSON.stringify(formData);
      console.log(formData.Myfile); 
      const postData = new FormData();
      postData.append('id', formData.id);
     
      postData.append('productid', id);
      if(formData.Myfile=='undefined')
{
  postData.append('image', "");
  postData.append('Myfile',"");
}else{
      postData.append('image', formData.Myfile);
      postData.append('Myfile',formData.Myfile);}
     
      
          const response = await axios.post('https://api.myonventure.com/api/Product/ProductImageUpdate', postData,{'headers':headers});
         
          setEditingItemId(null);
          fetchItems();
          setItems([...items, response.data]);
          setFormData({ id: '',productid:'',image:'',Myfile: null,
          });
          handleSwitchOperation('read');
   //   setItems(items.map(item => item.id === id ? { ...item, formData } : item));
     
    } catch (error) {
      console.error('Error editing item:', error);
    }
  };


  const showedititem=async(dataitem)=>{
    fetch();
    console.log(dataitem);
    setEditingItemId(dataitem.id);
    
    setSelectedOption(dataitem.category);
    console.log(selectedOption);
   
  
    setFormData({ id: dataitem.id, productid:dataitem.productid,image:dataitem.Myfile,
    Myfile:dataitem.Myfile });
    
    handleSwitchOperation('Edit');
  };

  const handleFileChange = (e) => {
    // Update the Myfile field in the formData state with the selected file object
    setFormData({ ...formData, Myfile: e.target.files[0] });
  };
  const setData=async(id)=>{
    setSelectedOption(id);
    
    setFormData({ ...formData, productid: id })
  };
  return (
    <>
                          
    <div className='border-1'>
      
      
       {/*  Add Operation Start  */}
     
           <div className='form-inline'>
  <input className='form-control'
                type="file" name="Myfile"
                onChange={handleFileChange}
    />&nbsp;<button className='btn btn-warning'  onClick={handleAddItem}>Upload</button>
   </div>
         {/*  Add Operation END  */}
     
         {/*  Edit Operation START  */}      
    {crudOperation === 'Edit' && ( <div className='form-inline'>

    <table className='table '><tbody>
 
        
      <tr><td>    Upload image </td><td> <input className='form-control'
                type="file" name="Myfile"
                onChange={handleFileChange}
    /></td></tr>
  <tr><td></td><td>             <button className='btn btn-info' onClick={handleEditItem}>Update Item</button>
              &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
      </td></tr></tbody>
</table>
   
    </div>
      )
    }
      {/*  Edit Operation END  */}  
     {/*  Read Operation Start  */}
    
     <div className='row'>
            {items.map((item, index) => {
     return (
<div  key={index} className='p-2'>
                                
                <div className='col col-md-3 p-2 text-center' ><img height={80}  className='img-responsive mb-2' src={'https://api.myonventure.com/api/../images/proimage/'+item.image}/>
    <br/>  <button className='btn btn-sm btn-danger' onClick={() => handleDeleteItem(item.id)}>Delete</button>
      
               </div>   
                </div>
   ) })}
            
           </div>
      
           {/*  Read Operation END  */}
      
    </div>
    
    </>
  );
}

export default Productimage;