
import { Route, Routes,BrowserRouter  } from 'react-router-dom';
import './App.css';
import ReactDOM from 'react-dom';
import React,{ useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from "react-router-dom"
import Login from './Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import Logout from './Logout';
import Country from './Pages/Country/Country';
import City from './Pages/City/City';
import State from './Pages/State/State';

import Category from './Pages/Category/Category';
import Subcategory from './Pages/Subcategory/Subcategory';
import Brand from './Pages/Brand/Brand';
import Products from './Pages/Products/Products';
import Clientlogo from './Pages/Clientlogo/Clientlogo';
import Agencylogo from './Pages/Agencylogo/Agencylogo';
 
import Datafacts from './Pages/Datafacts/Datafacts';
import Categorytype from './Pages/Categorytype/Categorytype';
import Language from './Pages/Language/Language';
import Advertisement from './Pages/Advertisement/Advertisement';
function App() {
  return (
   
    <Routes>
        <Route exact path="/"  element={<Login/>} />
        <Route exact path="/Login"  element={<Login/>} />
        <Route exact path="/Logout"  element={<Logout/>} />
        <Route exact path="/Dashboard"  element={<Dashboard/>} />
        <Route exact path="/Country"  element={<Country/>} />
        <Route exact path="/State"  element={<State/>} />
        <Route exact path="/City"  element={<City/>} />
    
        <Route exact path="/Category"  element={<Category/>} />
        <Route exact path="/Subcategory"  element={<Subcategory/>} />
        <Route exact path="/Products"  element={<Products/>} />
        <Route exact path="/Brand"  element={<Brand/>} />
        <Route exact path="/Clientlogo"  element={<Clientlogo/>} />
        <Route exact path="/Agencylogo"  element={<Agencylogo/>} />
        <Route exact path="/Datafacts"  element={<Datafacts/>} />
        <Route exact path="/Categorytype"  element={<Categorytype/>} />
        <Route exact path="/Language"  element={<Language/>} />
        <Route exact path="/Advertisement"  element={<Advertisement/>} />
    </Routes>
 
  );
}

export default App;
