import React,{ useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import './Login.css';
import Dashboard from './Pages/Dashboard/Dashboard';
function Login() {
    
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
 
    useEffect(()=>{
        if(localStorage.getItem('token') != "" && localStorage.getItem('token') != null){
          
           navigate("/Dashboard");
        }
        console.log(localStorage.getItem('token'))
    },[])
 
    const loginAction = (e) => {
        setValidationErrors({})
        e.preventDefault();
        setIsSubmitting(true)
        let payload = {
            email:email,
            password:password,
        }
        const tokens='1';
        axios.get('https://api.myonventure.com/api/Admin/Login?username=' + email + '&password=' + password +'&tokens='+tokens+'')
        .then((r) => {
            console.log(r);
            setIsSubmitting(false);
           
            if(r.data.length!==0)
            { localStorage.setItem('token', r.data.token); localStorage.setItem('adminuser', email); navigate("/Dashboard");}else{setValidationErrors('User  Name and Password Incorrect'); setIsSubmitting(false); localStorage.setItem('token', '');localStorage.setItem('adminuser', '');}
           
        })
        .catch((e) => {
            setIsSubmitting(false)
            if (e.response.data.errors !== undefined) {
                setValidationErrors(e.response.data.errors);
            }
            if (e.response.data.error !== undefined) {
                setValidationErrors(e.response.data.error);
            }
        });
    }
 
     
    return (
      <div>
  <div class="container">

 
<div class="row justify-content-center">

    <div class="col-xl-10 col-lg-12 col-md-9">

        <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
                {/* <!-- Nested Row within Card Body --> */}
                <div class="row">
                    <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                    <div class="col-lg-6">
                        <div class="p-5">
                            <div class="text-center">
                                <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                            </div>
                            <form class="user" onSubmit={(e)=>{loginAction(e)}}>
                                {Object.keys(validationErrors).length !== 0 &&
                                     <p className='text-center '><small className='text-danger'>Incorrect Email or Password</small></p>
                                }
                                
                      
                                <div class="form-group">
                                    <input type="text" className="form-control form-control-user"
                                       
                                        placeholder="Enter Username..."  
                                    
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(e)=>{setEmail(e.target.value)}}/>
                                </div>
                                <div class="form-group">
                                    <input type="password" className="form-control form-control-user"
                                   
                                   placeholder="Enter Password"  
                                      id="password"
                                      name="password"
                                      value={password}
                                      onChange={(e)=>{setPassword(e.target.value)}}/>
                                </div>
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox small">
                                        <input type="checkbox" class="custom-control-input" id="customCheck"/>
                                        <label class="custom-control-label" for="customCheck">Remember
                                            Me</label>
                                    </div>
                                </div>

                                <button 
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="btn btn-primary btn-user btn-block">Login</button>
                                                       
                            </form>
                            <hr/>
                            <div class="text-center">
                                <a class="small" href="forgot-password.html">Forgot Password?</a>
                           
                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

</div>

        

</div>
        
    );
}
   
export default Login;