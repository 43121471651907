import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../componants/header';
import Footer from '../../componants/footer';

function Category() {
  const [crudOperation, setCrudOperation] = useState('read'); // Default to 'read'
  const [items, setItems] = useState([]);

 
  const [formData, setFormData] = useState({
    id: '',
    name: '' ,
icon:'',orderno:''
    
  });
  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };
  useEffect(() => {
    fetchItems();
    
  }, []);


 

  const fetchItems = async () => {
    try {
      const tokens='1';
      const response = await axios.get('https://api.myonventure.com/api/Categories/categoryall?tokens='+tokens+'');
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

 

  const handleAddItem = async () => {
    try {
      
      const headers = { 'content-type': 'application/json'}  
  const body=JSON.stringify(formData);

  const postData = new FormData();
 
  postData.append('name', formData.name);
  postData.append('icon', formData.icon);
  postData.append('orderno', formData.orderno);
      const response = await axios.post('https://api.myonventure.com/api/Categories/Insert',  postData,{'headers':headers});
      console.log(postData);
      fetchItems();
      setItems([...items, response.data]);
      setFormData({ id: '', name: '',
      icon:'',orderno:'' });
      handleSwitchOperation('read');
      /* setNewItemText(''); */
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await axios.get('https://api.myonventure.com/api/Categories/Delete?id='+id+'');
      setItems(items.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleEditItem = async (dataitem1) => {
    try {
      const headers = { 'content-type': 'application/json'}  
      const body=JSON.stringify(formData);
    
      const postData = new FormData();
      postData.append('id', formData.id);
      postData.append('name', formData.name);
      postData.append('icon', formData.icon);
      postData.append('orderno', formData.orderno);
  
          const response = await axios.post('https://api.myonventure.com/api/Categories/Update', postData,{'headers':headers});
          console.log(postData); 
          setEditingItemId(null);
          fetchItems();
          setItems([...items, response.data]);
          setFormData({ id: '', name: '',
          icon:'',orderno:''});
          handleSwitchOperation('read');
   //   setItems(items.map(item => item.id === id ? { ...item, formData } : item));
     
    } catch (error) {
      console.error('Error editing item:', error);
    }
  };


  const showedititem=async(dataitem)=>{
    console.log(dataitem);
    setEditingItemId(dataitem.id);
   
   
   
    setFormData({ id: dataitem.id, name: dataitem.name,icon:dataitem.icon,orderno:dataitem.orderno });
    
    handleSwitchOperation('Edit');
  };

  return (
    <><Header>
          <div className="container-fluid">
                    {/*         <!-- Page Heading --> */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Category</h1>
                    <button className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onClick={() => handleSwitchOperation('add')}><i
                        className="fas fa-plus fa-sm text-white-50"></i> Add New</button>
                     <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                        className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
                </div>

                    
             
                <div className="row">

                    {/*     <!-- Area Chart --> */}
                    <div className="col-lg-12">
                        <div className="card shadow mb-4">
                            {/*  <!-- Card Header - Dropdown --> */}
                            <div
                                className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">Category View/Add/Update</h6>
                                <div className="dropdown no-arrow">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="dropdownMenuLink">
                                        <div className="dropdown-header">Dropdown Header:</div>
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                            {/*      <!-- Card Body --> */}
                            <div className="card-body">
                            <div>

                          
    <div>
       {/*  Read Operation Start  */}
       {crudOperation === 'read' && (
    <table className='table table-bordered'>
    <thead><tr>
                <th>Action</th>
                <th>Sr. No.</th>
              <th>ID</th>
                <th>Name</th>           
                <th>Icon</th>
                <th>Order No</th>  
            </tr></thead> 
            {items.map((item, index) => {
     return (
<tbody><tr  key={index}>
                <td style={{width:'auto'}}>    <button className='btn btn-sm btn-danger' onClick={() => handleDeleteItem(item.id)}>Delete</button>
         &nbsp;   <button  className='btn btn-sm  btn-info' onClick={() => showedititem(item)}>Edit</button></td>
                <td>{index + 1}</td>
                <td>{item.id}</td>   
                <td>{item.name}</td>               
                <td>{item.icon}</td>
                <td>{item.orderno}</td>

            </tr></tbody>
   ) })}
            
           </table>
       )}
           {/*  Read Operation END  */}
      
       {/*  Add Operation Start  */}
       {crudOperation === 'add' && (
           <div className='form-inline'>
<table className='table '>
  <tbody>
 
  <tr><td>    Enter Category Name </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          /></td></tr>
         <tr><td>    Enter fontawesome icon  </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.icon}
            onChange={(e) => setFormData({ ...formData, icon: e.target.value })}
          /></td></tr>
          <tr><td>    Enter Order no </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.orderno}
            onChange={(e) => setFormData({ ...formData, orderno: e.target.value })}
          /></td></tr>
  <tr><td></td><td>   <button className='btn btn-info'  onClick={handleAddItem}>Save</button> 
       &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
      </td></tr></tbody>
</table>

            
     
        
           
   </div>)}  
         {/*  Add Operation END  */}
     
         {/*  Edit Operation START  */}      
    {crudOperation === 'Edit' && ( <div className='form-inline'>

    <table className='table '><tbody>
 
  <tr><td>    Enter Category Name </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          /></td></tr>
         <tr><td>    Enter fontawesome icon  </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.icon}
            onChange={(e) => setFormData({ ...formData, icon: e.target.value })}
          /></td></tr>
          <tr><td>    Enter Order no </td><td> <input className='form-control'
            type="text"
            placeholder="Name"
            value={formData.orderno}
            onChange={(e) => setFormData({ ...formData, orderno: e.target.value })}
          /></td></tr>
  <tr><td></td><td>             <button className='btn btn-info' onClick={handleEditItem}>Update Item</button>
              &nbsp;  <button className='btn btn-info'  onClick={() => handleSwitchOperation('read')}>Exit</button>
      </td></tr></tbody>
</table>
   
    </div>
      )
    }
      {/*  Edit Operation END  */}  
    
      
    </div>
    </div>
                            </div>
                        </div>
                    </div>

                    </div>   
                </div>
    <Footer/></Header></>
  );
}

export default Category;