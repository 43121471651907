import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Link,BrowserRouter } from "react-router-dom";
import './header.css';
import Country from '../Pages/Country/Country';
import State from '../Pages/State/State';
import City from '../Pages/City/City';

import Category from '../Pages/Category/Category';
import Products from '../Pages/Products/Products';
import Subcategory from '../Pages/Subcategory/Subcategory';

function Header({ children })
{
    
 /*  window.onscroll = function () {scrollFunction();};

  function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
  
  document.getElementById("navbar").style.background = "#fff";
  } else {
  
  document.getElementById("navbar").style.background = "none";
  }
  } */
      

 return (
    
  <div id="wrapper">
  {/*    <!-- Start of Sidebar --> */}

<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

       
<Link class="sidebar-brand d-flex align-items-center justify-content-center" to="/dashboard">
<div class="sidebar-brand-icon rotate-n-15">
    <i class="fa fa-laugh-wink"></i>
</div>
<div class="sidebar-brand-text mx-3">HAMARA BRAND</div>
</Link>


<hr class="sidebar-divider my-0"/>


<li class="nav-item active">
<Link class="nav-link" to="/dashboard">
    <i class="fa fa-fw fa-tachometer-alt"></i>
    <span>Dashboard</span></Link>
</li>
<hr class="sidebar-divider my-0"/>



<hr class="sidebar-divider"/>


<div class="sidebar-heading">
Master LINK
</div>

<li class="nav-item">
<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
    aria-expanded="true" aria-controls="collapseTwo">
    <i class="fa fa-fw fa-cog"></i>
    <span>Master</span>
</a>
<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
    <div class="bg-white py-2 collapse-inner rounded">
        <h6 class="collapse-header">Master Links:</h6>
        <Link class="collapse-item" to="/Categorytype">Category Type</Link>
        <Link class="collapse-item" to="/Language">Language</Link>
        <Link class="collapse-item" to="/Country">Country</Link>
        <Link class="collapse-item" to="/Country">Country</Link>
        <Link class="collapse-item" to="/State">State</Link>
        <Link class="collapse-item" to="/City">City</Link>
       

    </div>
</div>
</li>
{/*       <!-- Nav Item - Utilities Collapse Menu --> */}
<li class="nav-item">
<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
    aria-expanded="true" aria-controls="collapseUtilities">
    <i class="fa fa-shopping-cart"></i>
    <span><b>Product Entry</b></span>
</a>
<div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
    data-parent="#accordionSidebar">
    <div class="bg-white py-2 collapse-inner rounded">
        <h6 class="collapse-header">Custom Product:</h6>
       
        <Link class="collapse-item" to="/Category">Category</Link>
        <Link class="collapse-item" to="/Subcategory">Sub Category</Link>
      {/*   <Link class="collapse-item" to="/Brand">Brand</Link> */}
        <Link class="collapse-item" to="/Products">Product View/Add/Update</Link>
       
    </div>
</div>
</li>
<li class="nav-item active">
<Link class="nav-link" to="/clientlogo">
    <i class="fa  fa-image"></i>
    <span>Client Logo</span></Link>
</li>
<li class="nav-item active">
<Link class="nav-link" to="/agencylogo">
    <i class="fa fa-image"></i>
    <span>Agency Logo</span></Link>
</li>
<li class="nav-item active">
<Link class="nav-link" to="/Advertisement">
<i class="fa fa-solid fa-rectangle-ad fa-image"></i>
    <span>Advertisement</span></Link>
</li>





<li class="nav-item">
<a class="nav-link" href="charts.html">
    <i class="fa fa-fw fa-chart-area"></i>
    <span>FAQ</span></a>
</li>




<hr class="sidebar-divider d-none d-md-block" />


<div class="text-center d-none d-md-inline">
<button class="rounded-circle border-0" id="sidebarToggle"></button>
</div>


<div class="sidebar-card d-none d-lg-flex">
<img class="sidebar-card-illustration mb-2" src="theme/img/undraw_rocket.svg" alt="..."/>
<p class="text-center mb-2"><strong>Sangam Tech Support</strong> </p>
<a class="btn btn-success btn-sm" href="phone:9251469851">+91-9251469851</a>
</div>

</ul>
{/*    <!-- End of Sidebar --> */}

{/*     <!-- Content Wrapper --> */}
<div id="content-wrapper" class="d-flex flex-column">

{/*     <!-- Main Content --> */}
<div id="content">

{/*           <!-- Topbar --> */}
<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

  {/*   <!-- Sidebar Toggle (Topbar) --> */}
    <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
        <i class="fa fa-bars"></i>
    </button>

   {/*  <!-- Topbar Search --> */}
    <form
        class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div class="input-group">
            <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                aria-label="Search" aria-describedby="basic-addon2"/>
            <div class="input-group-append">
                <button class="btn btn-primary" type="button">
                    <i class="fa fa-search fa-sm"></i>
                </button>
            </div>
        </div>
    </form>

{/*          <!-- Topbar Navbar --> */}
    <ul class="navbar-nav ml-auto">

  {/*       <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
        <li class="nav-item dropdown no-arrow d-sm-none">
            <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-search fa-fw"></i>
            </a>
{/*             <!-- Dropdown - Messages --> */}
            <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                aria-labelledby="searchDropdown">
                <form class="form-inline mr-auto w-100 navbar-search">
                    <div class="input-group">
                        <input type="text" class="form-control bg-light border-0 small"
                            placeholder="Search for..." aria-label="Search"
                            aria-describedby="basic-addon2"/>
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button">
                                <i class="fa fa-search fa-sm"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </li>

   {/*      <!-- Nav Item - Alerts --> */}
        <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-bell fa-fw"></i>
          {/*       <!-- Counter - Alerts --> */}
                <span class="badge badge-danger badge-counter">3+</span>
            </a>
       {/*      <!-- Dropdown - Alerts --> */}
            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="alertsDropdown">
                <h6 class="dropdown-header">
                    Alerts Center
                </h6>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fa fa-file-alt text-white"></i>
                        </div>
                    </div>
                    <div>
                        <div class="small text-gray-500">December 12, 2019</div>
                        <span class="font-weight-bold">A new monthly report is ready to download!</span>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="mr-3">
                        <div class="icon-circle bg-success">
                            <i class="fa fa-donate text-white"></i>
                        </div>
                    </div>
                    <div>
                        <div class="small text-gray-500">December 7, 2019</div>
                        $290.29 has been deposited into your account!
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="mr-3">
                        <div class="icon-circle bg-warning">
                            <i class="fa fa-exclamation-triangle text-white"></i>
                        </div>
                    </div>
                    <div>
                        <div class="small text-gray-500">December 2, 2019</div>
                        Spending Alert: We've noticed unusually high spending for your account.
                    </div>
                </a>
                <a class="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
            </div>
        </li>

  {/*       <!-- Nav Item - Messages --> */}
        <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-envelope fa-fw"></i>
          {/*       <!-- Counter - Messages --> */}
                <span class="badge badge-danger badge-counter">7</span>
            </a>
   {/*          <!-- Dropdown - Messages --> */}
            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="messagesDropdown">
                <h6 class="dropdown-header">
                    Message Center
                </h6>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="dropdown-list-image mr-3">
                        <img class="rounded-circle" src="img/undraw_profile_1.svg"
                            alt="..."/>
                        <div class="status-indicator bg-success"></div>
                    </div>
                    <div class="font-weight-bold">
                        <div class="text-truncate">Hi there! I am wondering if you can help me with a
                            problem I've been having.</div>
                        <div class="small text-gray-500">Emily Fowler · 58m</div>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="dropdown-list-image mr-3">
                        <img class="rounded-circle" src={process.env.PUBLIC_URL + 'img/undraw_profile_2.svg'}
                            alt="..."/>
                        <div class="status-indicator"></div>
                    </div>
                    <div>
                        <div class="text-truncate">I have the photos that you ordered last month, how
                            would you like them sent to you?</div>
                        <div class="small text-gray-500">Jae Chun · 1d</div>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="dropdown-list-image mr-3">
                        <i class="rounded-circle" src={process.env.PUBLIC_URL + 'undraw_profile_3.svg'}
                            alt="..."/>
                        <div class="status-indicator bg-warning"></div>
                    </div>
                    <div>
                        <div class="text-truncate">Last month's report looks great, I am very happy with
                            the progress so far, keep up the good work!</div>
                        <div class="small text-gray-500">Morgan Alvarez · 2d</div>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="dropdown-list-image mr-3">
                        <img class="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                            alt="..."/>
                        <div class="status-indicator bg-success"></div>
                    </div>
                    <div>
                        <div class="text-truncate">Am I a good boy? The reason I ask is because someone
                            told me that people say this to all dogs, even if they aren't good...</div>
                        <div class="small text-gray-500">Chicken the Dog · 2w</div>
                    </div>
                </a>
                <a class="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
            </div>
        </li>

        <div class="topbar-divider d-none d-sm-block"></div>


        <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">{localStorage.getItem('adminuser') }</span>
                <img class="img-profile rounded-circle"
                    src={process.env.PUBLIC_URL + 'theme/img/undraw_profile.svg'}/>
            </a>

            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown">
                <a class="dropdown-item" href="#">
                    <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                </a>
                <a class="dropdown-item" href="#">
                    <i class="fa fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    Settings
                </a>
                <a class="dropdown-item" href="#">
                    <i class="fa fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                    Activity Log
                </a>
                <div class="dropdown-divider"></div>
                <Link class="dropdown-item" to="/Logout" data-toggle="modal" data-target="#logoutModal">
                    <i class="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                </Link>
            </div>
        </li>

    </ul>

</nav>
{   /*    End of Topbar  */ }

{/*            Begin Page Content  */ }
{children}
{/*   /*   End of Main Content */} 


</div>
</div>
</div>
 );   
 
}

export default Header